export const en = {
  tour_operator_details: {
    titles: {
      tour_operator_details: 'Tour Operator Details',
    },
    table_columns: {
      tour_operator_name: 'Tour Operator Name',
      cabin_code: 'Cabin',
      contract_type: 'Contract Type',
      contract_code: 'Contract Code',
      initial: 'Initial',
      confirmed: 'Confirmed',
      sold: 'Sold',
      available: 'Available',
      initial_utilization: 'Initial Utilization',
      confirmed_utilization: 'Confirmed Utilization',
      initial_rate: 'Initial Rate',
      confirmed_rate: 'Confirmed Rate',
      sold_revenue: 'Sold Revenue',
      sold_average_rate: 'Sold Average Rate',
      total_avg: 'Total/Avg',
    },
  },
  tour_operator_pool: {
    buttons: {
      revert_changes: 'Revert Changes',
      save: 'Save',
    },
    dialogs: {
      reset_data: {
        title: 'Revert Changes',
        message:
          'All your changes will be lost and the data will be reset to the previously saved state. Are you sure you want to continue?',
        confirm_button: 'Revert Changes',
        cancel_button: 'Cancel',
      },
      save_data: {
        title: 'Save Changes',
        message: 'All your changes will be saved an applied to the selected flight. Are you sure you want to continue?',
        confirm_button: 'Save',
        cancel_button: 'Cancel',
      },
      save_success: {
        message: 'Saved Tour Operator changes successfully',
      },
    },
    table_column_header: {
      contract: 'Contract',
      fare: 'Fare',
      alloc: 'Alloc',
      adj_alloc: 'AdjAlloc',
      sold: 'Sold',
      sa: 'SA',
      calcClass: 'CalcClass',
      fareGrid: 'FareGrid',
      srcLAF: 'srcLAF',
      ceiling: 'Ceiling',
      offset: 'Offset',
    },
    table_column_header_description: {
      contract: 'Tour Operator Contract Code',
      fare: 'Tour Operator Active Fare/Price',
      alloc: 'Captured Number of Seats Allocated',
      adj_alloc: 'Current Number of Seats Allocated',
      sold: 'Number of Seats Sold part of the Allocation',
      sa: 'Seats Available for Sale within Allotment',
      calcClass: 'The Calculated Result of the Price Class - after applying the Price Controls',
      fareGrid: 'The Fare Table/Grid which is referenced to determine the Price using the Calculated Class',
      srcLAF: 'Method to determine the Calculated Class - Static Class (Class Code) or Dynamic (DNC)',
      ceiling: 'Ceiling Price Point',
      offset: 'Number of classes offset applied to a Dynamic Source LAF',
    },
    table_column_content: {
      class: 'Class',
      fare_value: 'Fare Value',
      dynamic: 'Dynamic',
      dynamic_short: 'DNC',
    },
  },
  tour_operator_messages: {
    failed_to_get_contract_types: 'Failed to get Tour Operator contract types',
    failed_to_get_tour_operator_names: 'Failed to get Tour Operator names',
  },
};
