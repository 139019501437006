import axios, { CancelTokenSource } from 'axios';
import chroma from 'chroma-js';
import { max } from 'd3-array';
import { has, keyBy, merge, uniq, uniqBy, values } from 'lodash-es';
import moment from 'moment';
import { defineStore, storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { GridFilterState } from '@/models/ag-grid/grid-state';
import { generateControlFilters } from '@/models/filters/collections/control';
import { coupleInvertedSearchIndex, filteredAirports } from '@/models/filters/utils';
import { AircraftTypeModel } from '@/modules/api/aircraft-types/aircraft-type-contracts';
import { aircraftTypeService } from '@/modules/api/aircraft-types/aircraft-type-service';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FlightLineModel, SlimFlightLineModel } from '@/modules/api/flight/flight-contracts';
import { ondsService } from '@/modules/api/flight/onds-service';
import { QueryModel } from '@/modules/api/queries/query-contracts';
import { FilterField, FilterFieldField, FilterFieldType, RouteFilterType } from '@/modules/api/shared-contracts';
import {
  CrossFilterType,
  CrossfilterConfiguration,
  getCrossFilterConfigurations,
} from '@/modules/control/models/crossfilter/crossfilter-configuration';
import { ControlFilterType } from '@/modules/control/types/control-filter.types';
import { useCustomerDefinedDataStore } from '@/modules/customer-defined-data/store/customer-defined-data.store';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { useFeaturesStore } from '@/modules/features/store/features.store';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { logger } from '@/modules/monitoring';
import { useMarketInfoStore } from '@/modules/route-management/store/market-info.store';
import { useRouteGroupsStore } from '@/modules/route-management/store/route-groups.store';
import { Dictionary } from '@/modules/shared/types/generic';
import { useSystemStore } from '@/modules/system-settings/store/system.store';
import { RoleModel } from '@/modules/user-management/api/role/role-contracts';
import { useUserStore } from '@/modules/user-settings/store/user.store';
import { COLOR_RANGE } from '@/modules/user-settings/utils/colors.utils';
import { DateTimeService } from '@/services/date-time.service';
import { QueryTransformService } from '@/services/query-transform.service';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

/**
 * This is the state that we actually use to store the crossfilter state in the Control Module.
 * We need to store the associated cabinCode as well to be able to restore the crossfilter state.
 */
export type CrossfilterState = {
  /**
   * Unique identifier for the crossfilter that also contains information about the associated cabinCode.
   */
  id: string;
  widget: CrossFilterType;
  selection: number[] | string[] | Date[] | null[];
  cabinCode?: string;
};

export const useControlStore = defineStore('control', () => {
  const flightLines: Ref<ReadonlyArray<SlimFlightLineModel>> = ref([]);
  const filteredFlightLines: Ref<ReadonlyArray<SlimFlightLineModel>> = ref([]);
  const selectedFlightLineIds: Ref<number[]> = ref([]);
  const selectedRowsCount: Ref<number> = ref(0);
  const isLoading: Ref<boolean> = ref(false);
  const flightReviewUpdated: Ref<boolean> = ref(false);
  const aircraftTypes: Ref<string[]> = ref([]);
  const areFiltersValid: Ref<boolean> = ref(true);
  const activeQuery: Ref<QueryModel | null> = ref(null);
  const isResultEmpty: Ref<boolean> = ref(false);
  const gridFilterState: Ref<GridFilterState | null> = ref(null);
  const crossfilterStates: Ref<CrossfilterState[]> = ref([]);
  const filters: Ref<FilterFieldDefinition[]> = ref([]);
  /**
   * @private
   */
  const source: Ref<CancelTokenSource | null> = ref(null);
  /**
   * @private
   * Freeze the Objects && Array to prevent accidental mutation of our default set of filters.
   */
  const defaultFilters: ReadonlyArray<FilterFieldDefinition> = Object.freeze(
    generateControlFilters().map((filter) => Object.freeze(filter)),
  );
  /**
   * @private
   */
  const deltaLafMaxDistance: ComputedRef<number> = computed(() => {
    const deltaLafs = filteredFlightLines.value.flatMap((flightLine) =>
      flightLine.cabins?.map((cabin) => cabin.deltaLowestAvailableFareClass),
    );
    const maxDeltaLaf = max(deltaLafs, (delta) => (delta === undefined ? 0 : Math.abs(delta)));
    return typeof maxDeltaLaf === 'number' ? maxDeltaLaf : 0;
  });

  /**
   * @public
   */
  const generateDeltaLafColorScheme: ComputedRef<{ color: string; deltaLaf: number }[]> = computed(() => {
    const indexAdjustedDeltaLafMaxDistance = deltaLafMaxDistance.value + 1;

    return chroma
      .scale(COLOR_RANGE?.slice(0, indexAdjustedDeltaLafMaxDistance))
      .colors(indexAdjustedDeltaLafMaxDistance)
      .flatMap((color, index) =>
        index === 0
          ? [{ color, deltaLaf: index }]
          : [
              { color, deltaLaf: index },
              { color, deltaLaf: -index },
            ],
      );
  });

  const selectedHubs: ComputedRef<FilterFieldDefinition[]> = computed(() => {
    const hubFilter = filters.value.find((filter) => filter.field === FilterFieldField.hub);
    return hubFilter && hubFilter.value;
  });

  const selectedFlightLines: ComputedRef<SlimFlightLineModel[]> = computed(
    () =>
      selectedFlightLineIds.value
        .map((flId) => flightLines.value.find((fl) => fl.id === flId))
        .filter((flightLine): flightLine is FlightLineModel => !!flightLine) ?? [],
  );

  const assignedFlightLines: ComputedRef<SlimFlightLineModel[]> = computed(() => {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    // Return flightLines that are assigned to the user, unless the user has the Admin role then return all flights
    // If assignedRoutes collection is empty it means all routes are assigned
    return user.value?.roles.find((role: RoleModel) => role.name === 'Flights.Admin') || !user.value?.assignedRoutes?.length
      ? selectedFlightLines.value
      : selectedFlightLines.value.filter((flightLine) =>
          user.value?.assignedRoutes?.find((assignedRoute) => assignedRoute.flightPath === flightLine?.flightPath),
        );
  });

  /**
   * The application has specific cross filters available based on some customer's settings.
   */
  const allAvailableCrossfilterConfigurations: ComputedRef<CrossfilterConfiguration[] | []> = computed(() => {
    const appSettingsStore = useAppSettingsStore();
    const customerSettingsStore = useCustomerSettingsStore();
    const featuresStore = useFeaturesStore();
    const settings = customerSettingsStore.settings;

    const crossFilterConfigurations = getCrossFilterConfigurations({
      cabinCodes: appSettingsStore.inventoryConfigurationProperties?.cabins.map((a) => a.code as CabinCode),
    });

    return settings
      ? crossFilterConfigurations.filter(
          (cf) =>
            !('filter' in cf) ||
            (typeof cf.filter === 'boolean' && cf.filter) ||
            (typeof cf.filter === 'function' &&
              cf.filter({ appSettings: appSettingsStore, customerSettings: settings, features: featuresStore.features })),
        )
      : [];
  });

  const filterGroups: ComputedRef<ControlFilterType[]> = computed(() => {
    const f: FilterFieldField[] = filters.value.map((filter) => filter.field as FilterFieldField);

    return uniq(
      f
        .map((filter) => {
          switch (filter) {
            case FilterFieldField.origin:
            case FilterFieldField.destination:
            case FilterFieldField.hub:
            case FilterFieldField.flightPath:
            case FilterFieldField.invertSearch:
              return ControlFilterType.origin_destination_hub;
            case FilterFieldField.dayOfWeek:
              return ControlFilterType.day_of_week;
            case FilterFieldField.departureDateRange:
              return ControlFilterType.departure_date_range;
            case FilterFieldField.tagId:
              return ControlFilterType.tags;
            case FilterFieldField.userId:
              return ControlFilterType.users;
            case FilterFieldField.aircraftType:
              return ControlFilterType.aircraft_type;
            case FilterFieldField.flightNumber:
              return ControlFilterType.flightNumber;
            case FilterFieldField.routeGroupId:
              return ControlFilterType.route_group;
            case FilterFieldField.carrierCode:
              return ControlFilterType.carrier_code;
            case FilterFieldField.autopilot:
              return ControlFilterType.autopilot;
            case FilterFieldField.optimizationProfile:
              return ControlFilterType.optimization_profile;
            case FilterFieldField.optimizationTactic:
              return ControlFilterType.optimization_tactic;
            case FilterFieldField.cluster:
              return ControlFilterType.cluster;
            case FilterFieldField.eventName:
              return ControlFilterType.eventName;
            case FilterFieldField.eventCluster:
              return ControlFilterType.eventCluster;
            case FilterFieldField.stops:
              return ControlFilterType.stops;
            case FilterFieldField.tourOperatorAllotment:
              return ControlFilterType.to_allotment;
            case FilterFieldField.tourOperatorContractType:
              return ControlFilterType.to_contract_type;
            case FilterFieldField.tourOperatorName:
              return ControlFilterType.to_contract_name;
            default:
              logger.error(new Error(`Filter field ${filter} not found in the control filter type`));
              break;
          }
        })
        .filter((filter) => !!filter) as ControlFilterType[],
    );
  });

  /**
   * The captureDate is a mandatory field in the FilterDefinition[] for the onds/search endpoint.
   * @note according to the documentation, the departureDate is mandatory (api/src/main/kotlin/com/kambr/eddy/api/ond/search/README.md)
   */
  const filtersFinal: ComputedRef<FilterFieldDefinition[]> = computed(() => {
    const systemStore = useSystemStore();

    return [
      ...filters.value,
      {
        field: FilterFieldField.captureDate,
        type: FilterFieldType.equal,
        value: systemStore.config?.captureDate,
      },
    ];
  });

  function setCrossfilterConfiguration(payload: CrossfilterState[]): void {
    crossfilterStates.value = [...payload];
  }

  function setCrossfilterState(payload: CrossfilterState): void {
    const updatedCrossFilterStates = [...crossfilterStates.value];

    updatedCrossFilterStates.splice(
      updatedCrossFilterStates.findIndex((cfState) => cfState.id === payload.id),
      1,
      payload,
    );

    crossfilterStates.value = updatedCrossFilterStates;
  }

  function setGridFilterAndSortState(state: GridFilterState | null): void {
    gridFilterState.value = state;
  }

  function setHubs(payload: string[]): void {
    const hubFilter = filters.value.find((filter) => filter.field === FilterFieldField.hub);
    if (hubFilter) {
      hubFilter.value = payload;
    }
  }

  function setFilter(payload: { value: any; index: number }): void {
    filters.value[payload.index].value = payload.value;
  }

  function setFilterValidity(payload: { value: any; index: number }): void {
    filters.value[payload.index].isValid = payload.value;
    areFiltersValid.value = !filters.value.some((fd) => fd.isValid === false);
  }

  function setOperator(payload: { value: any; index: number }): void {
    filters.value[payload.index].type = payload.value;
  }

  function setFlightLines(fls: ReadonlyArray<SlimFlightLineModel>): void {
    const customerSettingsStore = useCustomerSettingsStore();
    const customerDefinedDataStore = useCustomerDefinedDataStore();

    isResultEmpty.value = fls.length === 0;

    const newFlightLines = customerSettingsStore.settings?.hasCustomerDefinedDataEnabled
      ? fls.map((flightLine) => ({
          ...flightLine,
          customerDefinedData: customerDefinedDataStore.byFlightKey(flightLine.flightKey),
        }))
      : [...fls];

    flightLines.value = Object.freeze(newFlightLines);
  }

  function setSelectedFlightLines(flightLineIds: number[]): void {
    selectedFlightLineIds.value = flightLineIds;
  }

  function setFilteredFlightLines(flightLines: ReadonlyArray<SlimFlightLineModel>): void {
    filteredFlightLines.value = Object.freeze([...flightLines]);
  }

  function setSelectedRowsCount(rowCount: number): void {
    selectedRowsCount.value = rowCount;
  }

  function setAircraftTypes(aircraftTypesParam: AircraftTypeModel[]): void {
    const aircraftTypeFilter = filters.value.find((filter) => filter.field === FilterFieldField.aircraftType);

    const types = aircraftTypesParam.map((aircraftType: AircraftTypeModel) => aircraftType.type);

    if (aircraftTypeFilter) {
      aircraftTypeFilter.componentDataOptions = types;
    }

    aircraftTypes.value = types;
  }

  /**
   * Reset the current search filters. Take the User's configured filters into account.
   */
  function resetQueryFilters(): void {
    const userStore = useUserStore();
    const routeGroupsStore = useRouteGroupsStore();
    const systemStore = useSystemStore();
    const marketInfoStore = useMarketInfoStore();
    const userFilters: FilterFieldField[] = coupleInvertedSearchIndex(userStore.controlSettings.filters ?? []);

    const orderedFilters: FilterFieldDefinition[] = userFilters
      ?.filter(
        (filterName: FilterFieldField) =>
          // In some queries the flightNumber is stored as flightNumberRange.
          // That shouldn't be, but for now make sure the filter bar keeps working when a query has flightNumberRange defined
          // I can also happen that flightNumberRange is returned even when the flightNumber itself is also present, then we can just ignore the flightNumberRange
          !(filterName === FilterFieldField.flightNumberRange && userFilters?.includes(FilterFieldField.flightNumber)),
      )
      ?.map((filterName) => {
        if (filterName === FilterFieldField.flightNumberRange) {
          filterName = FilterFieldField.flightNumber;
        }

        return defaultFilters.find((filter) => filter.field === filterName);
      })
      .filter(Boolean) as FilterFieldDefinition[];

    filters.value = orderedFilters.map((def: FilterFieldDefinition) => {
      const filter = { ...def };

      switch (filter.field) {
        case FilterFieldField.origin:
        case FilterFieldField.destination:
          filter.componentDataOptions = filteredAirports(filter.field);
          filter.value = [];
          break;
        case FilterFieldField.hub:
        case FilterFieldField.flightPath:
          filter.componentDataOptions = marketInfoStore.uniqueHubs.map((hub) => ({
            ...hub,
            flightPaths: Array.from(hub.flightPaths),
          }));
          filter.value = [];
          break;
        case FilterFieldField.departureDateRange:
          filter.value = [
            DateTimeService.StripUTCOffset({
              date: systemStore.config?.captureDate || '',
            }),
            DateTimeService.StripUTCOffset({
              date: systemStore.config?.captureDate || '',
            }),
          ];
          break;
        case FilterFieldField.userId:
        case FilterFieldField.autopilot:
        case FilterFieldField.tourOperatorAllotment:
        case FilterFieldField.invertSearch:
          filter.value = null;
          break;
        case FilterFieldField.aircraftType:
          filter.componentDataOptions = [...aircraftTypes.value];
          filter.value = [];
          break;
        case FilterFieldField.routeGroupId:
          filter.componentDataOptions = [...routeGroupsStore.routeGroups];
          filter.value = [];
          break;
        case FilterFieldField.carrierCode:
          filter.componentDataOptions = systemStore.config?.carrierCodes || [];
          filter.value = [];
          break;
        case FilterFieldField.dayOfWeek:
        case FilterFieldField.optimizationProfile:
        case FilterFieldField.optimizationTactic:
        case FilterFieldField.cluster:
        case FilterFieldField.stops:
        case FilterFieldField.tourOperatorContractType:
        case FilterFieldField.tourOperatorName:
          filter.value = [];
          break;
        case FilterFieldField.tagId:
        case FilterFieldField.eventName:
        case FilterFieldField.eventCluster:
          break;
        default:
          filter.value = '';
          break;
      }

      return filter;
    });
  }

  function setLoadingState(loading: boolean): void {
    isLoading.value = loading;
  }

  function setPins(flightLines: SlimFlightLineModel[]): void {
    flightLines.forEach((flightLine) => {
      flightLine.pins = 0;

      flightLine.cabins?.forEach((cabin) => {
        cabin.sumOfPinnedClasses = cabin.classes.filter((cls) => cls.isAuthorizationUnitPinned || cls.isProtectionPinned).length;

        (flightLine.pins as number) += cabin.sumOfPinnedClasses;
      });
    });
  }

  function refilterOrigins(newVal: string[]): void {
    const originFilter = filters.value.find((filter) => filter.field === FilterFieldField.origin);
    if (originFilter) {
      originFilter.componentDataOptions =
        newVal.length === 0 ? filteredAirports(FilterFieldField.origin) : filteredAirports(FilterFieldField.destination, newVal);
    } else {
      logger.error(new Error(`Origin filter not found while re-filtering origins with value`), newVal);
    }
  }

  function refilterDestinations(newVal: string[]): void {
    const destinationFilter = filters.value.find((filter) => filter.field === FilterFieldField.destination);
    if (destinationFilter) {
      destinationFilter.componentDataOptions =
        newVal.length === 0 ? filteredAirports(FilterFieldField.destination) : filteredAirports(FilterFieldField.origin, newVal);
    }
  }

  function applyUrlParamsToQueryFilters(params: Record<string, string | string[]>): void {
    const filterFieldTypes: FilterFieldType[] = Object.values(FilterFieldType);

    filters.value.forEach((filter) => {
      const paramValue = params[filter.field];
      const paramType = params[`${filter.field}Type`];

      if (paramValue) {
        // A querystring can contain multiple values for the same key, so we need to check for that.
        // We do not support that for the filters, so we just take the first value.
        const sanitizedParamValue: string = Array.isArray(paramValue) ? paramValue?.[0] : (paramValue as string) ?? '';
        filter.value =
          typeof filter.transformFromParams === 'function'
            ? filter.transformFromParams(sanitizedParamValue, filters.value, params)
            : filter.value;
      }

      if (paramType && filterFieldTypes.includes(<FilterFieldType>paramType)) {
        // If the paramType passes the 'includes' condition above it is a FilterFieldType
        filter.type = paramType as FilterFieldType;
      }

      // Sometimes the 'ndo' type is set, but the value actually contains dates. Let's fix that, ugly I know, but we got a ticket to fix it properly. Yay!
      if (filter.field === FilterFieldField.departureDateRange) {
        if (filter.value[0] instanceof moment) {
          filter.type = filter.value.length > 1 ? FilterFieldType.between : FilterFieldType.equal;
        }
      }
    });
  }

  function setFlightReviewUpdated(bool: boolean): void {
    flightReviewUpdated.value = bool;
  }

  function setActiveQuery(payload: QueryModel | null): void {
    activeQuery.value = payload;
  }

  async function filtersApplied(
    params = {
      page: 0,
      size: 20000,
    },
  ): Promise<SlimFlightLineModel[] | unknown> {
    const customerSettingsStore = useCustomerSettingsStore();

    let response: SlimFlightLineModel[] = [];
    setSelectedRows([]);
    setLoadingState(true);

    source.value = axios.CancelToken.source();

    const customerDefinedDataStore = useCustomerDefinedDataStore();

    try {
      [response] = await Promise.all([
        ondsService.searchAll(filtersFinal.value, source.value.token, params, !!customerSettingsStore.settings?.useGoldModel),
        customerSettingsStore.settings?.hasCustomerDefinedDataEnabled ? customerDefinedDataStore.search(filtersFinal.value) : null,
      ]);

      setPins(response);
      setFlightLines(response);
      /**
       * Use `this.flightLines` set by `this.setFlightLines`, since that mutation enriches the flight lines with customer defined data
       * using the response directly would require us to set it in the filtered flight lines as well
       */
      setFilteredFlightLines(flightLines.value);
    } catch (err) {
      return err;
    } finally {
      setLoadingState(false);
    }

    return response;
  }

  async function getFlightLinesByFlightKeys(flightKeys: string[]): Promise<SlimFlightLineModel[] | unknown> {
    setSelectedRows([]);
    setLoadingState(true);

    let response: SlimFlightLineModel[] = [];

    source.value = axios.CancelToken.source();

    try {
      response = await ondsService.getByFlightKeys(flightKeys, undefined, source.value.token);

      setPins(response);
      setFlightLines(response);
      /**
       * Use `this.flightLines` set by `this.setFlightLines`, since that mutation enriches the flight lines with customer defined data
       * using the response directly would require us to set it in the filtered flight lines as well
       */
      setFilteredFlightLines(flightLines.value);
    } catch (err) {
      return err;
    } finally {
      setLoadingState(false);
    }

    return response;
  }

  async function clearSearchAndQuery(updateSettings: boolean = true): Promise<void> {
    clearFlightLines();
    resetQueryFilters();
    clearGridFilterAndSortState();
    await deselectQuery(updateSettings);
  }

  function setSelectedRows(flightLineIds: number[]): void {
    setSelectedFlightLines(flightLineIds);
    setSelectedRowsCount(flightLineIds.length);
  }

  /**
   * Here we map the list of user selected crossfilters to actual crossfilter state objects.
   * Make sure that the user selected widget types are still supported by the application by filtering them out.
   */
  async function setDefaultCrossfilterStates(updateSettings: boolean = true): Promise<void> {
    const { controlSettings } = useUserStore();
    const allCfConfigs = [...allAvailableCrossfilterConfigurations.value];

    const activeFilters: CrossfilterState[] = (controlSettings.crossfilterState || [])
      ?.map((userConfig) => {
        const filter = allCfConfigs.find(
          (state) => state.widgetType === userConfig.widget && state.widgetParams?.cabinCode === userConfig?.cabinCode,
        );

        if (!filter) return;

        const state: CrossfilterState = {
          id: filter.id,
          widget: filter.widgetType,
          selection: [],
          cabinCode: filter.widgetParams?.cabinCode,
        };
        return state;
      })
      .filter((a): a is CrossfilterState => Boolean(a));

    await setActiveCrossfilters({ filters: activeFilters, updateSettings });
  }

  /**
   * Merge the current state with the new state and update the store.
   */
  async function updateActiveCrossfilters(cfStates: CrossfilterState[]): Promise<void> {
    const newCrossfilterStates = values(merge(keyBy(crossfilterStates.value, 'id'), keyBy(cfStates, 'id')));
    await setActiveCrossfilters({ filters: newCrossfilterStates, updateSettings: true });
  }

  /**
   * Set the crossfilters that are visible in the application and update the User Settings
   * so the user can see the same crossfilters when he logs in again.
   */
  async function setActiveCrossfilters({
    filters,
    updateSettings,
  }: {
    filters: CrossfilterState[];
    updateSettings: boolean;
  }): Promise<void> {
    const userStore = useUserStore();
    const uniqueFilters = uniqBy(filters, 'id');
    setCrossfilterConfiguration(uniqueFilters);

    // check for explicitly being false instead of a falsy value as we'd like the default to be true
    if (updateSettings) {
      await userStore.updateControlSettings({
        crossfilterState: uniqueFilters.map((a) => ({
          widget: a.widget,
          cabinCode: a.cabinCode,
        })),
      });
    }
  }

  function updateCrossfilterState(payload: CrossfilterState): void {
    const widget = crossfilterStates.value.find((cfState) => cfState.id === payload.id);

    if (widget) {
      widget.selection = payload.selection;

      setCrossfilterState(widget);
    }
  }

  async function getStateFilters(): Promise<FilterFieldDefinition[]> {
    return filters.value?.length > 0 ? filters.value : await getDefaultQueryFilters();
  }

  // TODO: Check if we need to save these separately or not
  async function getDefaultQueryFilters(queryParameters: Dictionary<string | string[]> = {}): Promise<FilterFieldDefinition[]> {
    const userStore = useUserStore();

    // Set the filters from query parameters that are not yet in the user's configured filters
    const filtersLocal = userStore.controlSettings.filters ?? [];
    const filterFields = Object.values(FilterFieldField);
    const filteredFilters: FilterFieldField[] = Object.keys(queryParameters).filter((queryKey) =>
      filterFields.includes(<FilterFieldField>queryKey),
    ) as FilterFieldField[];

    userStore.updateControlSettingsLocally({
      filters: [...filtersLocal, ...filteredFilters.filter((queryFilter) => !filtersLocal.includes(queryFilter))].filter(Boolean),
    });

    // Check if the query parameters does change the current active Market Filter Type (e.g. from OnD to Hub).
    if (has(queryParameters, FilterFieldField.hub)) {
      userStore.updateControlFiltersByRouteFilterTypeLocally(RouteFilterType.hub);
    } else if (has(queryParameters, FilterFieldField.origin)) {
      userStore.updateControlFiltersByRouteFilterTypeLocally(RouteFilterType.origin_destination);
    }

    if (userStore.controlSettings?.routeFilterType === RouteFilterType.hub) {
      userStore.updateControlFiltersByRouteFilterTypeLocally(RouteFilterType.hub);
    } else if (userStore.controlSettings?.routeFilterType === RouteFilterType.origin_destination) {
      userStore.updateControlFiltersByRouteFilterTypeLocally(RouteFilterType.origin_destination);
    }

    resetQueryFilters();

    if (Object.keys(queryParameters).length > 0) {
      applyUrlParamsToQueryFilters(queryParameters);
    }

    if (flightReviewUpdated.value) {
      await filtersApplied();
    }

    return filters.value;
  }

  function updateFilter(payload: { value: any; index: number }): void {
    setFilter(payload);

    if (filters.value[payload.index].field === FilterFieldField.origin) {
      refilterDestinations(payload.value);
    }

    if (filters.value[payload.index].field === FilterFieldField.destination) {
      refilterOrigins(payload.value);
    }
  }

  function updateFilterValidity(payload: { value: { value: any; type: FilterFieldType }; index: number }): void {
    setFilterValidity(payload);
  }

  function clearFlightLines(): void {
    setFlightLines([]);
    setFilteredFlightLines([]);
  }

  function updateFlightReviewUpdated(bool: boolean): void {
    setFlightReviewUpdated(bool);
  }

  function updateGridFilterState(state: GridFilterState | null): void {
    setGridFilterAndSortState(state);
  }

  function clearGridFilterAndSortState(): void {
    setGridFilterAndSortState(null);
  }

  function cancelSearch(): void {
    source.value?.cancel();
    setLoadingState(false);
  }

  async function getAircraftTypes(): Promise<void> {
    const aircraftTypes = await aircraftTypeService.getAll();
    setAircraftTypes(aircraftTypes);
  }

  /**
   * Convert the Query to the associated search filters, crossfilters and gridstate and replace the current state with the new state.
   */
  async function selectQuery(selectedQuery: QueryModel): Promise<void> {
    const userStore = useUserStore();
    const { controlSettings, controlGridColumnState } = storeToRefs(userStore);

    // In some queries the flightNumber is stored as flightNumberRange.
    // That shouldn't be, but for now make sure the filter bar keeps working when a query has flightNumberRange defined
    const flightNumberRangeField = selectedQuery.query.fields.find(
      (query: FilterField) => query.field === FilterFieldField.flightNumberRange,
    );
    if (flightNumberRangeField) {
      flightNumberRangeField.field = FilterFieldField.flightNumber;
    }

    // Set the selected query as the active query
    setActiveQuery(selectedQuery);

    const activeCrossFilters: CrossfilterState[] = [];
    for (const queryState of selectedQuery.crossfilterState) {
      /**
       * Get the actual cross filter configuration by the query state.
       * We do this because the querystate does not contain an ID that we can match on.
       * TODO: (KB) align this model with the {@link CrossfilterConfiguration}
       */
      const cfConf = allAvailableCrossfilterConfigurations.value.find(
        (config) => config.widgetType === queryState.widget && config.widgetParams?.cabinCode === queryState?.cabinCode,
      );

      if (cfConf) {
        activeCrossFilters.push({
          id: cfConf.id,
          widget: cfConf.widgetType,
          selection: queryState?.selection ?? [],
          cabinCode: queryState?.cabinCode,
        });
      }
    }

    const newQueryFilters: FilterFieldField[] = [...(controlSettings.value.filters || [])];

    // This will set filters fields in the view when they have a value and are not enabled in the view in the sidebar config section.
    (selectedQuery.query?.fields as FilterField[])
      .map((filter: FilterField) => filter.field as FilterFieldField)
      .map((filterField: FilterFieldField) => {
        if (!newQueryFilters.includes(filterField)) {
          newQueryFilters.unshift(filterField);
        }
      });

    const queryIndex = newQueryFilters.findIndex((query) => query === FilterFieldField.departureDate);

    if (queryIndex > -1) {
      newQueryFilters.splice(queryIndex, 1);
    }

    // Set control config values in the saved settings
    const newControlSettings = {
      filters: newQueryFilters,
      crossfilterState: merge(
        controlSettings.value.crossfilterState,
        activeCrossFilters.map((a) => ({
          widget: a.widget,
          cabinCode: a.cabinCode,
        })),
      ),
      competitors: selectedQuery.competitors,
      groupKey: selectedQuery.groupKey,
      routeFilterType: selectedQuery.routeFilterType,
    };

    await userStore.updateUserSettings({
      controlBookingsPickUpPoints: selectedQuery.bookingsPickups,
      controlPerformanceBandPickUpPoints: selectedQuery.performanceBandPickups,
      controlSettings: newControlSettings,
    });

    resetQueryFilters();
    const query = QueryTransformService.transformToParams(selectedQuery.query.fields as FilterFieldDefinition[]);

    await getDefaultQueryFilters(query);

    setCrossfilterConfiguration(activeCrossFilters);

    // Set the grid filter state
    setGridFilterAndSortState(selectedQuery.queryGridFilterState ?? null);

    // Set the column state
    controlGridColumnState.value = selectedQuery.queryGridColumnState;
  }

  async function deselectQuery(updateSettings: boolean = true): Promise<void> {
    setActiveQuery(null);
    await setDefaultCrossfilterStates(updateSettings);
  }

  async function $reset(): Promise<void> {
    clearSearchAndQuery(false);
  }

  return {
    filtersFinal,
    flightLines,
    filteredFlightLines,
    selectedFlightLineIds,
    selectedRowsCount,
    isLoading,
    filters,
    flightReviewUpdated,
    activeQuery,
    isResultEmpty,
    gridFilterState,
    crossfilterStates,
    generateDeltaLafColorScheme,
    selectedHubs,
    selectedFlightLines,
    assignedFlightLines,
    allAvailableCrossfilterConfigurations,
    filterGroups,
    areFiltersValid,
    aircraftTypes,
    setHubs,
    setOperator,
    filtersApplied,
    getFlightLinesByFlightKeys,
    clearSearchAndQuery,
    setDefaultCrossfilterStates,
    updateActiveCrossfilters,
    setActiveCrossfilters,
    updateCrossfilterState,
    getStateFilters,
    updateFilter,
    updateFilterValidity,
    updateFlightReviewUpdated,
    updateGridFilterState,
    cancelSearch,
    getAircraftTypes,
    selectQuery,
    setSelectedRows,
    resetQueryFilters,
    getDefaultQueryFilters,
    setFlightLines,
    setFilteredFlightLines,
    refilterOrigins,
    refilterDestinations,
    setSelectedFlightLines,
    $reset,
  };
});
