import { Moment } from 'moment';

import { InventoryGridModel } from '@/modules/api/flight/inventory-grid-model';
import { AggregationType } from '@/modules/shared/shared-contracts';

export enum FilterGroupType {
  or = 'or',
  and = 'and',
}

// System config
export enum FilterFieldType {
  between = 'between',
  equal = 'eq',
  in = 'in',
  all = 'all_in',
  ndo = 'ndo',
  none = 'none',
  noneOf = 'none_of',
}

export enum FilterFieldField {
  addLinkedClassRule = 'add_linked_class_rule',
  addOptimisationProfileLevel = 'add_optimisation_profile_level',
  addRivalRule = 'add_rival_rule',
  addSaOfLaf = 'add_seats_lowest_available_fare',
  aircraftType = 'aircraftType',
  applyDataScienceRecommendedLaf = 'apply_datascience_recommended_laf',
  applyRecommendedAU = 'apply_recommended_au',
  applyRecommendedOverbookingFactor = 'apply_recommended_overbooking_factor',
  authorizedCapacityActions = 'authorizedCapacityActions',
  autopilot = 'autopilot',
  captureDate = 'captureDate',
  carrierCode = 'carrierCode',
  changeAU = 'updatedAuthorizationUnits',
  changeBalancedAdjustment = 'change_balanced_adjustment',
  changeDiscontinueDate = 'updatedDiscontinuedDate',
  changeEffectiveDate = 'updatedEffectiveDate',
  changeMinControl = 'updatedMinControl',
  changeOptimisationTactic = 'change_optimisation_tactic',
  changeOverbooking = 'updateOverbooking',
  changePR = 'updatedProtection',
  changeSA = 'seatAvailability',
  changeSegmentLimit = 'updatedSegmentLimit',
  changeShadowOptimisationTactic = 'change_shadow_optimisation_tactic',
  changeUnbalancedAdjustment = 'change_unbalanced_adjustment',
  cluster = 'cluster',
  clusterActions = 'cluster_actions',
  correctAUStructure = 'correctAUStructure',
  dayOfWeek = 'dayOfWeek',
  departureDate = 'departureDate',
  departureDateRange = 'departureDateRange',
  destination = 'destination',
  eventCluster = 'eventCluster',
  eventName = 'eventName',
  flight = 'flight',
  flightActions = 'flight',
  flightNumber = 'flightNumber',
  flightNumberRange = 'flightNumberRange',
  flightPath = 'flightPath',
  flightStatus = 'flightStatus',
  hub = 'hub',
  laf = 'laf',
  lafActions = 'lafActions',
  legCaptureDate = 'legCaptureDate',
  legDepartureDate = 'legDepartureDate',
  manualAdjustments = 'manualAdjustments',
  matchCompetitor = 'set_lowest_available_fare_to_match_competitor',
  month = 'month',
  moveClusterLevelDown = 'move_cluster_level_down',
  moveClusterLevelUp = 'move_cluster_level_up',
  moveLowestAvailableFareDown = 'move_lowest_available_down',
  moveLowestAvailableFareUp = 'move_lowest_available_fare_up',
  moveOptimizationProfileLevelDown = 'move_optimization_profile_level_down',
  moveOptimizationProfileLevelUp = 'move_optimization_profile_level_up',
  moveRecommendedOverbookingRiskPercentageDown = 'move_recommended_overbooking_risk_percentage_down',
  moveRecommendedOverbookingRiskPercentageUp = 'move_recommended_overbooking_risk_percentage_up',
  moveShadowOptimizationProfileLevelDown = 'move_shadow_optimization_profile_level_down',
  moveShadowOptimizationProfileLevelUp = 'move_shadow_optimization_profile_level_up',
  moveTacticalAdjustmentLevelDown = 'move_tactical_adjustment_level_down',
  moveTacticalAdjustmentLevelUp = 'move_tactical_adjustment_level_up',
  ondCaptureDate = 'ondCaptureDate',
  ondDepartureDate = 'ondDepartureDate',
  optimizationActions = 'optimizationActions',
  optimizationProfile = 'optimizationProfile',
  optimizationTactic = 'optimizationTactic',
  origin = 'origin',
  overbookingAutopilot = 'overbooking_autopilot',
  priceAggregation = 'price_aggregation',
  pricingActions = 'pricing_actions',
  pricingTactic = 'pricing_tactic',
  removeCluster = 'remove_cluster',
  removeLinkedClassRule = 'remove_linked_class_rule',
  removePromotion = 'remove_promotion',
  removeRivalRule = 'remove_rival_rule',
  removeSourceFlight = 'remove_source_flight',
  resolveLeftover = 'resolveLeftover',
  invertSearch = 'invertSearch',
  revert = 'revert',
  routeGroupId = 'routeGroupId',
  ruleActions = 'ruleActions',
  sendFlightsToPss = 'sendFlightsToPss',
  setAuBelowLafToZero = 'set_au_below_laf_to_zero',
  setPromotion = 'set_promotion',
  setRecommendedOverbookingRiskPercentage = 'set_recommended_overbooking_risk_percentage',
  setSaOfLaf = 'set_seats_lowest_available_fare',
  stops = 'stops',
  swapOptimisationTactic = 'swap_optimisation_tactic',
  tacticalAdjustment = 'tactical_adjustment',
  tagId = 'tagId',
  tagsAdd = 'tagsAdd',
  tagsRemove = 'tagsRemove',
  tourOperatorAllotment = 'tourOperatorAllotment',
  tourOperatorContractType = 'tourOperatorContractType',
  tourOperatorName = 'tourOperatorName',
  updateClusterLevel = 'update_cluster_level',
  updateSourceFlight = 'update_source_flight',
  userId = 'userId',
  week = 'week',
  year = 'year',
}

export enum RouteFilterType {
  hub = 'HUB',
  origin_destination = 'ORIGIN_DESTINATION',
}

export interface FilterGroup {
  type: FilterGroupType;
  fields: Array<FilterField | FilterGroup>;
}

export interface FilterField {
  field: FilterFieldField | InventoryGridModel | AggregationType;
  type: FilterFieldType | FilterGroupType;
  value?: string | number | any[] | string[] | number[] | Moment[];
}

export interface FilterFieldValid extends FilterField {
  isValid(item: FilterField | FilterGroup): boolean;
}

export interface Page {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}

export interface Pagination<T> {
  content: T[];
  page: Page;
}
