<template>
  <div>
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { ComputedRef, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import { useAuthStore } from '@/modules/external/store/auth.store';
import { RouteName } from '@/modules/shared';
import { useSingleInventoryManagementStore } from '@/modules/tour-operator-details/stores/single-inventory-management.store';
import { useUserStore } from '@/modules/user-settings/store/user.store';
import { FlightService } from '@/services/flight.service';
import { useFlightStore } from '@/store/modules/flight.store';

const router = useRouter();
const authStore = useAuthStore();
const userStore = useUserStore();
const { fontSize } = storeToRefs(userStore);

const fontSizeWithSuffix: ComputedRef<string> = computed(() => `${fontSize.value}px`);

onBeforeMount(() => {
  // TODO: This shouldn't be on app level,
  // It should move to the page that is blocking navigation
  window.addEventListener('beforeunload', (event) => {
    if (
      FlightService.hasUnsavedChanges(useFlightStore().flightActions) ||
      useSingleInventoryManagementStore().singleInventoryManagementGridDirty
    ) {
      event.preventDefault();
      event.returnValue = '';
    }
  });

  if (!authStore.token && !window.location.pathname.startsWith('/external')) {
    router.push({
      name: RouteName.login,
      query: {
        redirect: window.location.pathname + window.location.search,
      },
    });
  }
});
</script>

<style lang="scss">
// Import all application wide styles
@import '@/styles/index.scss';

// Override the font size for all grids
:root,
.ag-theme-alpine {
  --ag-font-size: v-bind(fontSizeWithSuffix);
}
</style>
